.toast-container > .toast-success {
  background-color: #a8e09f;
  color: #01590a;
}

.toast-container > .toast-warning {
  background-color: #fce5cd;
  color: #ff9942;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+DQo8cGF0aCBmaWxsPSdyZ2IoMjU1LCAxNTMsIDY2KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==);
}

.toast-container > .toast-error {
  background-color: #f18fa7;
  color: $lgRed;
}

.toast-container > .toast-warning .toast-close-button {
  color: #ff9942;
}

.toast-container > .toast-warning .toast-progress {
  background-color: #ff9942;
}

.toast-container {
  position: fixed;
  top: 60px;
  left: 65%;
  transform: translate(-50%, -50%);

  .ngx-toastr {
    width: 100% !important;
    max-width: 440px;
  }
}

// En mode PC, le toast s'affiche en haut à droite de l'écran
@include media-breakpoint-up(md) {
  .toast-container {
      position: fixed;
      top: 60px;
      left: 65%;
      transform: translate(-50%, -50%);

    .ngx-toastr {
      width: 440px !important;
    }
  }
}
