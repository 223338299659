$lgRed:         #E31B18;
$lgGrey:        #EFEFEF;
$lgDarkGrey:    #999999;
$lgGreen:       #2EA88B;
$lgGreenLight:  #CEF3EA;
$lgBlanck:      #FFFFFF;
$lgSable:       #F0ECE4;
$lgGranit:      #2E2836;
$lgBleu:        #244AB0;
$lgNectar:      #FFB65C;
$lgGranySmith:  #BCDD73;
$lgViolet:      #885DFF;

/* HEADER */
/* STYLE INITIALE */
/*
$lgBackgroundHeader:                $lgBlanck;
$lgBackgroundInitiale:              $lgGreen;
$lgBackgroundInitialeHover:         $lgDarkGrey;
$lgColorInitiale:                   $lgBlanck;
$lgColorInitialeHover:              $lgBlanck;
$lgBorderInitiale:                  $lgSable;
$lgBackgroundTitreSunLeon:          $lgGreen;
$lgColorLienParametre:              $lgGreen;
$lgColorLienParametreHover:         $lgDarkGrey;
$lgColorBouton:                     $lgBlanck;
$lgColorBoutonHover:                $lgGrey;
$lgBackgroundNav:                   $lgBlanck;
$lgColorLienFocusNav:               $lgBlanck;
$lgColorLienActiveNav:              $lgGreen;
$lgBackgroundHeaderNewSimu:         $lgBlanck;
$lgColorNewSimu:                    $lgGreen;
$lgColorNewSimuHover:               $lgDarkGrey;

$lgBackgroundBoutonHome:            $lgGreen;
$lgColorBoutonHome:                 $lgBlanck;
$lgColorBoutonHomeHover:            $lgGrey;
$lgBackgroundBoutonHomeActive:      $lgBlanck;
$lgColorBoutonHomeActive:           $lgGreen;
$lgColorBoutonHomeActiveHover:      $lgBlanck;

$lgBackgroundFormParent:            transparent;
$lgBackgroundCard:                  $lgBlanck;
$lgShadowCard:                      $lgGranit;

$lgBackgroundBoutonActiveZone1:      $lgRed;
$lgColorBoutonActiveZone1:           $lgBlanck;
$lgColorBoutonActiveHoverZone1:      $lgRed;

$lgBackgroundBoutonActiveZone2:      $lgDarkGrey;
$lgColorBoutonActiveZone2:           $lgBlanck;
$lgColorBoutonActiveHoverZone2:      $lgDarkGrey;

$lgBackgroundBoutonActiveZone3:      $lgGranit;
$lgColorBoutonActiveZone3:           $lgBlanck;
$lgColorBoutonActiveHoverZone3:      $lgGranit;
*/

/* STYLE VERT + BLANC */

$lgBackgroundHeader:          $lgGreen;
$lgBackgroundInitiale:        $lgBlanck;
$lgBackgroundInitialeHover:   $lgSable;
$lgColorInitiale:             $lgGranit;
$lgColorInitialeHover:        $lgGranit;
$lgBorderInitiale:            $lgSable;
$lgBackgroundTitreSunLeon:    $lgBlanck;
$lgColorLienParametre:        $lgBlanck;
$lgColorLienParametreHover:   $lgSable;
$lgColorBouton:               $lgBlanck;
$lgColorBoutonHover:          $lgGrey;
$lgBackgroundNav:             $lgBlanck;
$lgColorLienFocusNav:         $lgBlanck;
$lgColorLienActiveNav:        $lgGreen;
$lgBackgroundHeaderNewSimu:   $lgGreen;
$lgColorNewSimu:              $lgBlanck;
$lgColorNewSimuHover:         $lgGrey;

$lgBackgroundBoutonHome:            $lgBlanck;
$lgColorBoutonHome:                 $lgGreen;
$lgColorBoutonHomeHover:            $lgGrey;
$lgBackgroundBoutonHomeActive:      $lgGreen;
$lgColorBoutonHomeActive:           $lgBlanck;
$lgColorBoutonHomeActiveHover:      $lgGreen;

$lgBackgroundFormParent:            transparent; //; //$lgSable
$lgBackgroundCard:                  $lgBlanck;
$lgShadowCard:                      $lgGranit;

$lgBackgroundBoutonActiveZone1:      $lgNectar;
$lgColorBoutonActiveZone1:           $lgBlanck;
$lgColorBoutonActiveHoverZone1:      $lgNectar;

$lgBackgroundBoutonActiveZone2:      $lgGranySmith;
$lgColorBoutonActiveZone2:           $lgBlanck;
$lgColorBoutonActiveHoverZone2:      $lgGranySmith;

$lgBackgroundBoutonActiveZone3:      $lgViolet;
$lgColorBoutonActiveZone3:           $lgBlanck;
$lgColorBoutonActiveHoverZone3:      $lgViolet;

/* STYLE VERT + SABLE */
/*
$lgBackgroundHeader:          $lgGreen;
$lgBackgroundInitiale:        $lgSable;
$lgBackgroundInitialeHover:   $lgDarkGrey;
$lgColorInitiale:             $lgGranit;
$lgColorInitialeHover:        $lgGranit;
$lgBorderInitiale:            $lgSable;
$lgBackgroundTitreSunLeon:    $lgSable;
$lgColorLienParametre:        $lgSable;
$lgColorLienParametreHover:   $lgGranit;
$lgColorBouton:               $lgSable;
$lgColorBoutonHover:          $lgGranit;
$lgBackgroundNav:             $lgSable;
$lgColorLienFocusNav:         $lgSable;
$lgColorLienActiveNav:        $lgGreen;
$lgBackgroundHeaderNewSimu:   $lgGreen;
$lgColorNewSimu:              $lgSable;
$lgColorNewSimuHover:         $lgGranit;

$lgBackgroundBoutonHome:            $lgSable;
$lgColorBoutonHome:                 $lgGreen;
$lgColorBoutonHomeHover:            $lgGrey;
$lgBackgroundBoutonHomeActive:      $lgGreen;
$lgColorBoutonHomeActive:           $lgSable;
$lgColorBoutonHomeActiveHover:      $lgGreen;

$lgBackgroundFormParent:            transparent;
$lgBackgroundCard:                  $lgBlanck;
$lgShadowCard:                      $lgGranit;

$lgBackgroundBoutonActiveZone1:      $lgRed;
$lgColorBoutonActiveZone1:           $lgBlanck;
$lgColorBoutonActiveHoverZone1:      $lgRed;

$lgBackgroundBoutonActiveZone2:      $lgDarkGrey;
$lgColorBoutonActiveZone2:           $lgBlanck;
$lgColorBoutonActiveHoverZone2:      $lgDarkGrey;

$lgBackgroundBoutonActiveZone3:      $lgGranit;
$lgColorBoutonActiveZone3:           $lgBlanck;
$lgColorBoutonActiveHoverZone3:      $lgGranit;
*/

/* STYLE VERT + NECTAR */
/*
$lgBackgroundHeader:            $lgGreen;
$lgBackgroundInitiale:          $lgNectar;
$lgBackgroundInitialeHover:     $lgBlanck;
$lgColorInitiale:               $lgBlanck;
$lgColorInitialeHover:          $lgNectar;
$lgBorderInitiale:              $lgSable;
$lgBackgroundTitreSunLeon:      $lgNectar;
$lgColorLienParametre:          $lgNectar;
$lgColorLienParametreHover:     $lgBlanck;
$lgColorBouton:                 $lgNectar;
$lgColorBoutonHover:            $lgGrey;
$lgBackgroundNav:               $lgNectar;
$lgColorLienFocusNav:           $lgNectar;
$lgColorLienActiveNav:          $lgGreen;
$lgBackgroundHeaderNewSimu:     $lgNectar;
$lgColorNewSimu:                $lgBlanck;
$lgColorNewSimuHover:           $lgGrey;

$lgBackgroundBoutonHome:            $lgGreen;
$lgColorBoutonHome:                 $lgNectar;
$lgColorBoutonHomeHover:            $lgGrey;
$lgBackgroundBoutonHomeActive:      $lgNectar;
$lgColorBoutonHomeActive:           $lgBlanck;
$lgColorBoutonHomeActiveHover:      $lgNectar;

$lgBackgroundFormParent:            transparent;
$lgBackgroundCard:                  $lgBlanck;
$lgShadowCard:                      $lgGranit;

$lgBackgroundBoutonActiveZone1:      $lgRed;
$lgColorBoutonActiveZone1:           $lgBlanck;
$lgColorBoutonActiveHoverZone1:      $lgRed;

$lgBackgroundBoutonActiveZone2:      $lgDarkGrey;
$lgColorBoutonActiveZone2:           $lgBlanck;
$lgColorBoutonActiveHoverZone2:      $lgDarkGrey;

$lgBackgroundBoutonActiveZone3:      $lgGranit;
$lgColorBoutonActiveZone3:           $lgBlanck;
$lgColorBoutonActiveHoverZone3:      $lgGranit;
*/
