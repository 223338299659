@import '1variables';
@import '2bootstrap-config';
@import "@ng-select/ng-select/themes/default.theme.css";
@import 'ngx-toastr/toastr';
@import "bootstrap/scss/bootstrap";
@import "bootstrap-icons/font/bootstrap-icons";

@import '3toaster';

body {
  padding-top: 70px;

  --scrollbar-width: calc(100vw - 100%);

  --colorSurfaceZone1: #FFB65C80; //#E31B18;
  --colorSurfaceZone2: #BCDD7380;
  --colorSurfaceZone3: #885DFF80;//#2E2836;
  --colorSurfaceModeValorisation: #000000;
  --colorZone1: #FFB65C; //#E31B18;
  --colorZone2: #BCDD73;
  --colorZone3: #885DFF;//#2E2836;
  --colorModeValorisation: #000000;
}

.btn-type-pose-options{
  width: 8rem;
}

.btn-primary{
  color: $lgColorBouton;
}

span.required {
  color: $lgRed;
  margin-left: 4px;
}

// Mise en forme du ng-select pour ressembler aux composants Bootstrap 5
.ng-select {
  .ng-select-disabled > .ng-select-container {
    background-color: #e9ecef;
  }

  &.is-invalid .ng-select-container {
    border-color: $lgRed !important;
  }

}

.ng-select:focus> .ng-select-container {
  border: 1px solid #FFFFFF;
  box-shadow: 0 0 0 0.4rem rgba(46, 168, 139, 0.25);
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container{
  border-color:$lgGreen;
  box-shadow: 0 0 0 0.4rem rgba(46, 168, 139, 0.25);
}

.container-fluid{
  width: 99%;
}

.sunleon-container {
  width: 98%;
  border: 1px solid #efefef;
  text-align: justify;
  //padding-bottom: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.sunleon-container-parametre {
  text-align: justify;
  padding-bottom: 1rem;
  margin-top: 1rem;
}

.bouton{
  text-align: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.btn-submit{
  width: 15rem;
  color: $lgColorBouton;
  font-size: 1.1rem;
}

.btn-submit:hover{
  color: $lgColorBoutonHover;
}

.btn-modal {
  width: 10rem;
  color: $lgColorBouton;
  font-size: 1.1rem;
}

.btn-modal:hover{
  color: $lgColorBoutonHover;
}

.list-group {
  > .list-group-item {
    min-height: 40px;
    display: flex;
    align-items: center;
  }
}

.list-group-item:first-child {
  padding-bottom: 1rem;
}

/* accordion */
.accordion-button {
  background-color: $lgGrey;
  padding: 10px 10px 10px 10px;
  font-size: 1rem;
  color:$lgGreen;
  font-style:normal;
  font-weight: 500;
  line-height: 1.2;
}

.accordion-button::after {
  margin-left: 10px;
}

/* Styles CSS personnalisés */
th {
  background-color: #FFFFFF; /* Couleur de fond de l'en-tête du tableau */
}

/* Couleur du texte pour les cellules du tableau */
.table td {
  color: #000000; /* Couleur du texte pour les cellules */
}

/* Couleur du texte pour la première colonne */
.table th,
.table th:first-child,
.table td:first-child {
  color: $lgGreen; /* Couleur du texte pour la première colonne */
}


.table tbody tr:hover {
  background-color: $lgGreenLight; /* Couleur de survol */
}

.selected-row {
  background-color: $lgGreenLight /* Couleur après le clic */
}

/* Ajoutez ces styles dans votre fichier CSS ou SCSS */
.icon-color-change {
  color: $lgGreen; /* Couleur initiale de l'icône */
}

.icon-color-change:hover {
  color: $lgDarkGrey; /* Couleur au survol de l'icône */
}

/* NOUVEAUTES FOND DE CARTE */

.floating-form {
  position: fixed;
  top: 9rem;
  right:0;
  z-index: 1030;
  width: 33.5%;
  background-color: $lgBackgroundFormParent;
  height: 80vh;
  overflow-y: auto; /* Auto le défilement vertical */
  scrollbar-color: $lgGrey $lgGreen;
  scrollbar-width: thin;
}

.card-body {
  padding: 0.5rem;
}

.card{
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px $lgShadowCard;
  background-color: $lgBackgroundCard;

  h1{
    color:$lgGreen;
    font-size: 1.5rem;
    font-style:normal;
    padding-bottom: 0.5rem;
  }

  h2{
    color:$lgGreen;
    font-size: 1rem;
    font-style:normal;
    //padding-top: 0.5rem;
  }

  span{
    font-weight: 500;
  }

  .form-label{
    color: $lgDarkGrey;
  }

  .obligatoires{
    color: $lgDarkGrey;
  }

  .form-check-input{
    width: 1.4rem;
    height: 1.5rem;
    margin-top: 0;
  }

  .form-check {
    margin-top: 1rem;
  }

  .spanTextMiddle {
    vertical-align: sub;
    margin-left: -1rem;
    color: #999999;
  }

  .form-check-inline {
    margin-top: 1rem;
    margin-left: 0.4rem;
  }

  .spanCheckbox {
    color: #999999;
    display: block;
    font-weight: normal;
    margin-left: -1.5rem;
    margin-top: -0.8rem;
    margin-bottom: 0.8rem;
  }

  .spanCheckboxMemeLigne {
    color: #999999;
    display: contents;
    font-weight: normal;
  }

  .bouton{
    text-align: right;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

}

.onglet-form-button-active-zone1 {
  background-color: $lgBackgroundBoutonActiveZone1;
  color: $lgColorBoutonActiveZone1;
}

.onglet-form-button-active-zone1:hover{
  background-color: $lgColorBoutonActiveHoverZone1;
}

.onglet-form-button-nonactive-zone1 {
  background-color: $lgBackgroundBoutonHome;
  color: $lgColorBoutonHome;
}

.onglet-form-button-nonactive-zone1:hover{
  background-color: $lgBackgroundBoutonActiveZone1;
  color: $lgColorBoutonActiveZone1;
}

.onglet-form-button-active-zone1 {
  background-color: $lgBackgroundBoutonActiveZone1;
  color: $lgColorBoutonActiveZone1;
}

.onglet-form-button-active-zone1:hover{
  background-color: $lgColorBoutonActiveHoverZone1;
}

.onglet-form-button-nonactive-zone1 {
  background-color: $lgBackgroundBoutonHome;
  color: $lgColorBoutonHome;
}

.onglet-form-button-nonactive-zone1:hover{
  background-color: $lgBackgroundBoutonActiveZone1;
  color: $lgColorBoutonActiveZone1;
}

.onglet-form-button-active-zone2 {
  background-color: $lgBackgroundBoutonActiveZone2;
  color: $lgColorBoutonActiveZone2;
}

.onglet-form-button-active-zone2:hover{
  background-color: $lgColorBoutonActiveHoverZone2;
}

.onglet-form-button-nonactive-zone2 {
  background-color: $lgBackgroundBoutonHome;
  color: $lgColorBoutonHome;
}

.onglet-form-button-nonactive-zone2:hover{
  background-color: $lgBackgroundBoutonActiveZone2;
  color: $lgColorBoutonActiveZone2;
}

.onglet-form-button-active-zone3 {
  background-color: $lgBackgroundBoutonActiveZone3;
  color: $lgColorBoutonActiveZone3;
}

.onglet-form-button-active-zone3:hover{
  background-color: $lgColorBoutonActiveHoverZone3;
  color: $lgColorBoutonActiveZone3;
}

.onglet-form-button-nonactive-zone3 {
  background-color: $lgBackgroundBoutonHome;
  color: $lgColorBoutonHome;
}

.onglet-form-button-nonactive-zone3:hover{
  background-color: $lgBackgroundBoutonActiveZone3;
  color: $lgColorBoutonActiveZone3;
}

